"use client";

import { PHIA_COOKIE_ID } from "@/constants";
import { MixpanelEventName } from "@/types";
import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useAnalytics from "./useAnalytics";
import { useNavigationEvents } from "./useNavigationEvents";
import usePhiaSearchParams from "./usePhiaSearchParams";

const DEBOUNCE_TIME_MS = 500;

interface PageViewProperties {
  phia_id: string | null;
  domain: string;
  [key: string]: string | null;
}

// Tracks route changes with debouncing to prevent duplicate events
export default function useRouteChangeTracker() {
  const url = useNavigationEvents();
  const { logClientSideUserEventToMixpanel } = useAnalytics();

  // Using refs to persist values between renders without causing re-renders
  const lastUrlRef = useRef<string | null>(null);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const searchParams = usePhiaSearchParams();
  const phiaId = useMemo(() => {
    const phiaIdParam = searchParams.get(PHIA_COOKIE_ID);
    const phiaIdCookie = Cookies.get(PHIA_COOKIE_ID);
    return phiaIdParam ?? phiaIdCookie ?? null;
  }, [searchParams]);

  // Any other search params that are in use for the current page
  const allOtherSearchParams = useMemo(() => {
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      if (key !== PHIA_COOKIE_ID) {
        params[key] = value;
      }
    });
    return params;
  }, [searchParams]);

  const getPageViewProperties = useCallback((): PageViewProperties => {
    return {
      phia_id: phiaId,
      domain: window.location.hostname,
      ...allOtherSearchParams,
    };
  }, [phiaId, allOtherSearchParams]);

  const clearDebounceTimer = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = null;
    }
  }, []);

  const logPageView = useCallback(() => {
    lastUrlRef.current = url;
    console.log(
      "logPageView",
      url,
      lastUrlRef.current,
      getPageViewProperties()
    );
    logClientSideUserEventToMixpanel({
      name: MixpanelEventName.PAGE_VIEW,
      properties: getPageViewProperties(),
    });
  }, [url, logClientSideUserEventToMixpanel, getPageViewProperties]);

  const handleRouteChange = useCallback(() => {
    if (lastUrlRef.current && url === lastUrlRef.current) {
      return;
    }

    clearDebounceTimer();
    debounceTimerRef.current = setTimeout(logPageView, DEBOUNCE_TIME_MS);
  }, [url, clearDebounceTimer, logPageView]);

  useEffect(() => {
    handleRouteChange();
    return clearDebounceTimer;
  }, [handleRouteChange, clearDebounceTimer]);
}
